<script lang="ts" setup>
import { ref, watch } from 'vue'
import type { DropdownInstance } from 'element-plus'

const { data, status, signIn, signOut } = useAuth()
const route = useRoute()

</script>
<template>
  <el-header>
    <div class="container">
      <div class="header-container">
        <NuxtLink to="/">
          <img src="@/assets/images/kayo-logo.png" class="logo" alt="Kayo logo">
        </NuxtLink>
        <div class="user-panel">
        </div>
      </div>
    </div>
  </el-header>
</template>
